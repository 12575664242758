<script>
import { required, email } from "vuelidate/lib/validators";

import {
  authMethods,
  authFackMethods,
  notificationMethods,
} from "@/state/helpers";

import appConfig from "@/app.config";

/**
 * Auth-login component
 */
export default {
  page: {
    title: "Login",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      email: "admin@pichforest.com",
      password: "123456",
      submitted: false,
      authError: null,
      tryingToLogIn: false,
      isAuthError: false,
    };
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    notificationAutoCloseDuration() {
      return this.$store && this.$store.state.notification ? 5 : 0;
    },
  },
  validations: {
    email: { required, email },
    password: { required },
  },
  methods: {
    ...authMethods,
    ...authFackMethods,
    ...notificationMethods,
    // Try to log the user in with the username
    // and password they provided.
    tryToLogIn() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
          this.tryingToLogIn = true;
          // Reset the authError if it existed.
          this.authError = null;
          return (
            this.logIn({
              email: this.email,
              password: this.password,
            })
              // eslint-disable-next-line no-unused-vars
              .then((token) => {
                this.tryingToLogIn = false;
                this.isAuthError = false;
                // Redirect to the originally requested page, or to the home page
                this.$router.push(
                  this.$route.query.redirectFrom || { name: "home" }
                );
              })
              .catch((error) => {
                this.tryingToLogIn = false;
                this.authError = error ? error : "";
                this.isAuthError = true;
              })
          );
        } else if (process.env.VUE_APP_DEFAULT_AUTH === "fakebackend") {
          const { email, password } = this;
          if (email && password) {
            this.login({ email, password });
          }
        }
      }
    },
  },
};
</script>

<template>
  <div class="account-pages my-5 pt-sm-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-10">
          <div class="text-center mb-5">
            <router-link to="/" class="auth-logo">
              <img
                src="@/assets/images/logo-dark.png"
                alt=""
                height="28"
                class="auth-logo-dark"
              />
              <img
                src="@/assets/images/logo-light.png"
                alt=""
                height="28"
                class="auth-logo-light"
              />
            </router-link>
            <p class="font-size-15 text-muted mt-3">
              Responsive <b>Bootstrap 5</b> Admin Dashboard
            </p>
          </div>
          <div class="card overflow-hidden">
            <div class="row g-0">
              <div class="col-lg-6">
                <div class="p-lg-5 p-4">
                  <div>
                    <h5>Welcome Back !</h5>
                    <p class="text-muted">Sign in to continue to Samply.</p>
                  </div>
                  <b-alert
                    :variant="notification.type"
                    class="mt-3"
                    v-if="notification.message"
                    :show="notificationAutoCloseDuration"
                    dismissible
                    @dismissed="clear"
                    >{{ notification.message }}</b-alert
                  >

                  <b-alert
                    variant="danger"
                    class="mt-3"
                    v-model="isAuthError"
                    :show="notificationAutoCloseDuration"
                    dismissible
                    @dismissed="clear"
                    >{{ authError }}</b-alert
                  >
                  <div class="mt-4 pt-3">
                    <form @submit.prevent="tryToLogIn">
                      <div class="mb-3">
                        <label for="email" class="fw-semibold">Email</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="email"
                          id="email"
                          placeholder="Enter email"
                          :class="{
                            'is-invalid': submitted && $v.email.$error,
                          }"
                        />
                        <div
                          v-if="submitted && $v.email.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.email.required"
                            >Email is required.</span
                          >
                          <span v-if="!$v.email.email"
                            >Please enter valid email.</span
                          >
                        </div>
                      </div>

                      <div class="mb-3 mb-4">
                        <label for="userpassword" class="fw-semibold"
                          >Password</label
                        >
                        <input
                          type="password"
                          v-model="password"
                          class="form-control"
                          id="userpassword"
                          placeholder="Enter password"
                          :class="{
                            'is-invalid': submitted && $v.password.$error,
                          }"
                        />
                        <div
                          v-if="submitted && !$v.password.required"
                          class="invalid-feedback"
                        >
                          Password is required.
                        </div>
                      </div>

                      <div class="row align-items-center">
                        <div class="col-6">
                          <div class="form-check">
                            <input
                              type="checkbox"
                              class="form-check-input font-size-16"
                              id="remember-check"
                            />
                            <label class="form-check-label" for="remember-check"
                              >Remember me</label
                            >
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="text-end">
                            <button
                              class="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >
                              Log In
                            </button>
                          </div>
                        </div>
                      </div>

                      <div class="mt-4">
                        <router-link to="/auth/recoverpwd-1" class="text-muted"
                          ><i class="mdi mdi-lock me-1"></i> Forgot your
                          password?</router-link
                        >
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="p-lg-5 p-4 bg-auth h-100 d-none d-lg-block">
                  <div class="bg-overlay"></div>
                </div>
              </div>
            </div>
          </div>
          <!-- end card -->
          <div class="mt-5 text-center">
            <p>
              Don't have an account ?
              <router-link
                to="/register"
                class="fw-semibold text-decoration-underline text-primary"
              >
                Sign up
              </router-link>
            </p>
            <p>
              © {{ new Date().getFullYear() }} <b>Samply</b>. Crafted with
              <i class="mdi mdi-heart text-danger"></i> by Pichforest
            </p>
          </div>
        </div>
        <!-- end col -->
      </div>
      <!-- end row -->
    </div>
    <!-- end container -->
  </div>
  <!-- end account page -->
</template>
